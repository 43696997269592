@keyframes line-scale-pulse-out {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(1);
  }
}

.image-slides-view-port {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.image-slides-gallery {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  transition: transform 0.25s;
  will-change: transform;
}

.image-slides-blackboard {
  display: inline-flex;
  position: absolute;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.image-slides-blackboard > * {
  overflow: hidden;
}

.image-slides-content {
  transform-origin: 0 0;
  will-change: transform;
  user-select: none;
  pointer-events: all;
}