.image-slides-portal {
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.image-preview-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.85;
  pointer-events: all;
}