/* ------------------------------------------------------------------------------
 *
 *  # Timeline
 *
 *  Styles for timeline in 3 layouts: left, right and centered
 *
 *  Version: 1.1
 *  Latest update: Dec 11, 2015
 *
 * ---------------------------------------------------------------------------- */
.timeline {
    position: relative;
}
.timeline:before,
.timeline:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2px;
    background-color: #ccc;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    z-index: 1;
}
.timeline:after {
    top: auto;
    bottom: 0;
}
.timeline-container {
    position: relative;
    padding-top: 10px;
    margin-top: -10px;
    padding-bottom: 1px;
    margin-bottom: 10px;
}
.timeline-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    background-color: #ccc;
    height: 100%;
    width: 2px;
    z-index: -1;
}
.timeline-row {
    position: relative;
}
.timeline-content {
    margin-bottom: 20px;
}
.timeline-date {
    text-align: center;
    background-color: #fff;
    position: relative;
    z-index: 1;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.timeline-date:before,
.timeline-date:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2px;
    background-color: #ccc;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    z-index: 1;
}
.timeline-date:after {
    top: auto;
    bottom: 0;
}
.card .timeline-date {
    background-color: #fff;
}
.timeline-time {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    position: relative;
    margin-bottom: 20px;
}
.timeline-time:before,
.timeline-time:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2px;
    background-color: #ccc;
    height: 4px;
    width: 4px;
    border-radius: 50%;
}
.timeline-time:after {
    top: auto;
    bottom: 0;
}
.card .timeline-time {
    background-color: #fff;
}
.timeline-time span {
    display: block;
    color: #999999;
    font-size: 12px;
}
/* .timeline-time span:before {
    content: "\2022";
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 1px;
} */
@media (min-width: 1025px) {
    .timeline-time:before,
    .timeline-time:after {
        content: none;
    }
}
.timeline-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 0 auto 20px auto;
    background-color: #fff;
    border: 4px solid #fff;
}
.card .timeline-icon {
    border-color: #fff;
}
.timeline-icon div {
    border-radius: 50%;
    height: 100%;
    -webkit-box-shadow: 0 0 0 2px #ccc inset;
    box-shadow: 0 0 0 2px #ccc inset;
}
.timeline-icon div[class*="bg-"] {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.timeline-icon div > i {
    display: block;
    line-height: 40px;
    top: 0;
}
.timeline-icon img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.timeline .card .card-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.timeline .card .card-body.sent,
.timeline .card .card-body.global {
    background-color: rgba(0, 0, 0, 0.03);
}
.timeline .card .card-body.sent pre,
.timeline .card .card-body.global pre {
    color: #70737c;
}

.timeline .card .show > .btn-outline-light.dropdown-toggle,
.timeline .card .btn-outline-light:not(:disabled):not(.disabled):active {
    border-color: rgba(0, 0, 0, 0.1);
}

/* .timeline .card .btn {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    min-height: inherit;
    background-color: #fff;
    color: #7987a1;
    border: 1px solid #cdd4e0;
} */

@media (min-width: 1025px) {
    .timeline-center .timeline-row {
        margin-right: 50%;
        padding-right: 44px;
    }
    .timeline-center .post-even {
        margin-right: 0;
        padding-right: 0;
        margin-left: 50%;
        padding-left: 44px;
    }
    .timeline-center .post-full {
        margin: 0;
        padding: 0;
    }
    .timeline-center .post-full .timeline-content {
        position: relative;
    }
    .timeline-center .post-full .timeline-content:before,
    .timeline-center .post-full .timeline-content:after {
        content: "";
        border-style: solid;
        border-color: #fff;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
    }
    .timeline-center .post-full .timeline-content:before {
        border-width: 4px 0 0 0;
        top: -5px;
    }
    .timeline-center .post-full .timeline-content:after {
        border-width: 0 0 4px 0;
        bottom: -5px;
    }
    .timeline-left .timeline-container:after,
    .timeline-left:before,
    .timeline-left:after,
    .timeline-left .timeline-date:before,
    .timeline-left .timeline-date:after {
        left: 24px;
    }
    .timeline-left .timeline-row,
    .timeline-left .timeline-date {
        padding-left: 68px;
    }
    .timeline-right .timeline-container:after,
    .timeline-right:before,
    .timeline-right:after,
    .timeline-right .timeline-date:before,
    .timeline-right .timeline-date:after {
        left: auto;
        right: 24px;
        margin-left: 0;
        margin-right: -2px;
    }
    .timeline-right .timeline-container:after {
        margin-right: -1px;
    }
    .timeline-right .timeline-row,
    .timeline-right .timeline-date {
        padding-right: 68px;
    }
    .timeline-left .timeline-date,
    .timeline-right .timeline-date {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .timeline-icon {
        position: absolute;
        top: 10px;
    }
    .timeline-icon:after {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -1px;
        height: 2px;
        width: 16px;
        background-color: #ccc;
        z-index: 1;
    }
    .timeline-left .timeline-icon {
        left: 0;
    }
    .timeline-left .timeline-icon:after {
        left: 100%;
        margin-left: 4px;
    }
    .timeline-right .timeline-icon {
        right: 0;
    }
    .timeline-right .timeline-icon:after {
        right: 100%;
        margin-right: 4px;
    }
    .timeline-center .timeline-row:not(.post-full) .timeline-icon {
        left: 100%;
        margin-left: -24px;
    }
    .timeline-center .timeline-row.post-even .timeline-icon {
        left: auto;
        right: 100%;
        margin-left: 0;
        margin-right: -24px;
    }
    .timeline-center .timeline-icon:after {
        right: 100%;
        margin-right: 4px;
    }
    .timeline-center .post-even .timeline-icon:after {
        left: 100%;
        margin-left: 4px;
    }
    .timeline-center .post-full .timeline-icon {
        position: static;
    }
    .timeline-center .post-full .timeline-icon:after {
        content: none;
    }
    .timeline-time {
        padding: 0;
        text-align: inherit;
        background-color: transparent;
    }
    .timeline-time:before {
        content: none;
    }
    .timeline-left .timeline-time,
    .timeline-right .timeline-time {
        padding-top: 10px;
        margin-bottom: 20px;
        padding-left: 10px;
    }
    .timeline-right .timeline-time {
        text-align: right;
        padding-left: 0;
        padding-right: 10px;
    }
    .timeline-center .timeline-time,
    .timeline-center .post-full .timeline-time {
        position: absolute;
        left: 100%;
        top: 15px;
        width: 100%;
        padding-left: 44px;
    }
    .timeline-center .post-even .timeline-time,
    .timeline-center .post-full .timeline-time {
        left: auto;
        right: 100%;
        padding-left: 0;
        padding-right: 44px;
        text-align: right;
    }
    .timeline-center .post-full .timeline-time {
        right: 50%;
        top: 5px;
    }
}
